import React, { Component } from 'react';
import './landing.scss';
import { animateScroll } from "react-scroll";

class Header extends Component {
  scrollDown() {
    animateScroll.scrollTo(window.innerHeight);
  }
  render() {
    return (
      <div className="App">
        <div className="App-header">
          <div className="chevronContainer">
            <div className="center">
              {/* <button onClick={()=> this.scrollDown()} className="scroller">╲╱</button> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header;