import React from "react";
import Landing from './landing';
import HomePage from './homePage';

const Home = () => (

    <div className='app'>
        <Landing />
        <HomePage />
    </div>
);

export default Home