import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Navigation from './components/navigation';
import Home from './pages/home/home';
import ContactPage from './pages/contact/contact';
import Projects from './pages/projects/projects';
import './App.scss';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => (
  <div className='app'>
    <Navigation />
    <Switch>
      <Route exact path='/' component={Home}></Route>
      <Route exact path='/contact' component={Contact}></Route>
      <Route exact path='/projects' component={Projects}></Route>
    </Switch>
  </div>
);

const Contact = () => (
  <ContactPage />
);

export default App;