import {Container} from 'react-bootstrap';
import './homePage.scss';

const HomePage = () => {
    return (
        <Container>
            {/* <Row xs={1} md={2}>
                <Col className="imageDiv img1 left"></Col>
                <Col className="textDiv right"><p>
                    Hi! I've recently graduated Electrical Engineering and Computer Science from Queensland University of Technology (QUT) in Brisbane. <br/>
                    After completing my studies, I moved down to Canberra to kick off my Engineering career with GHD in their Security team. 
                    <br/><br/>
                    Outside of work, I love to spend time Bouldering, watching sport (NRL, Rugby, Cricket), baking, and just generally staying active. 
                    </p>
                </Col>
            </Row>
            <Row xs={1} md={2}>
                <Col className="textDiv left"><p>
                    Professionally, I've worked in a few different fields in the past couple years. I started in hospitality, and later moved into a student support role with QUT.
                    Following this, I spent 2 years with Symplicity Corporation working in quality assurance, whilst also taking on client support tasks and development projects. I also undertook a software Engineering internship with Aerospace company Burrana, followed by a short vacationer program with Deloitte Digital. 
                    </p>
                </Col>
                <Col className="imageDiv img2 right"></Col>
            </Row>
            <Jumbotron>
                <Row xs={1} md={2}>
                    <Col className="center">
                        <Button className="btn btn-primary action" href='./contact'>
                            Get in touch
                        </Button>
                    </Col>
                    <Col className="center">
                        <Button className="btn btn-primary action" href={pdf}>
                            Download Resume
                        </Button>
                    </Col>
                </Row>
            </Jumbotron> */}
        </Container>
    )
}

export default HomePage;