import React, { Component } from 'react';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import './contact.scss';

class Contact extends Component {
    render() {
        return (
            <Container className="Welcome">
                <Row>
                    <Col md={12} lg={5}>
                        <Row>
                            <Col>
                                <h1>Feel free to say hi!</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h2>Whether you want to let me know something, want to hear a bit more, or just want to chat!</h2>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={0} lg={1}></Col>
                    <Col md={12} lg={6}>

                        <form
                            name="contact"
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            method="post"
                        >
                            <input type="hidden" name="form-name" value="contact" />
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Row>
                                        <Form.Label>Email *</Form.Label>
                                        <Form.Control name="email" type="email" placeholder="Your email" required></Form.Control>
                                    </Row>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Row>
                                        <Form.Label>Name *</Form.Label>
                                        <Form.Control name="name" placeholder="Your name" required/>
                                    </Row>
                                </Form.Group>
                            </Form.Row>
                            <Form.Group>
                                <Row>
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control name="company" placeholder="Your company" />
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Form.Label>Message *</Form.Label>
                                    <Form.Control name="message" as="textarea" placeholder="If you've got something to say, I want to hear it!" required/>
                                </Row>
                            </Form.Group>
                            <Form.Group controlId="bot-field" style={{ display: "none" }}>
                                <Row>
                                    <Form.Label>Don’t fill this out if you're human:</Form.Label>
                                    <Form.Control as="textarea" placeholder="If you've got something to say, I want to hear it!" />
                                </Row>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </form>

                    </Col>
                </Row>
            </Container>
        )
    }
}


export default Contact;
