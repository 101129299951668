import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import './projects.scss';
import dice from '../../assets/2-Dice-Icon.png';
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Projects = () => {
    return(
        <Container>
            <Row>
                <Col className="icon left" xs={4}>
                    <img src={dice} alt="dice"></img>
                </Col>
                <Col className="description right" xs={8}>
                    <h3>Reddit D20 Python Bot</h3>
                    <a href="https://github.com/Dugmendio/reddit-d20-bot"><FontAwesomeIcon icon={faGithub} /> Github</a>
                    <p>Developed a small python script to allow reddit users to roll a dice, and respond in a comment.</p>
                </Col>
            </Row>
            <Row>
                <Col className="icon left" xs={4}>
                    <img src={'https://www.qut.edu.au/__data/assets/file/0007/779740/logo-white.svg?v=0.1.1'} alt="QUT Logo"></img>
                </Col>
                <Col className="description right" xs={8}>
                    <h3>QUT Culture Walk</h3>
                    <p>Worked for an Indigenous client to develop a showcase of Indigenous Australian Culture through Augmented Reality. Managed a team of 4 as project manager and team leader, implementing agile methodologies to meet client requests in an appropriate timeline.</p>
                </Col>
            </Row>
            <Row>
                <Col className="icon left" xs={4}>
                    <img src={'https://upload.wikimedia.org/wikipedia/commons/c/cf/New_Power_BI_Logo.svg'} alt="PowerBI"></img>
                </Col>
                <Col className="description right" xs={8}>
                    <h3>Asset Health Index Research Project</h3>
                    <p>Developed a PowerBI suite to allow rapid evaluation of transformer performance and ageing characteristics.</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Projects;