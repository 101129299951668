import { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { animateScroll } from 'react-scroll';
import logo from '../assets/logo.svg';

class Navigation extends Component {

    scrollDown() {
        animateScroll.scrollTo(window.innerHeight);
    }
    scrollTop() {
        animateScroll.scrollTo(0);
    }

    render() {
        return (
            <Navbar bg="light" expand="lg" sticky="top">
                <Navbar.Brand onClick={()=> this.scrollTop()}><img src={logo} alt="logo"></img> Jesse Mason</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href='/'>Home</Nav.Link>
                        <Nav.Link href='/projects'>Projects</Nav.Link>
                        <Nav.Link href="/contact">Contact</Nav.Link>
                        {/* <a href={pdf} download="JesseMasonResume.pdf" className="nav-link">Download resume</a> */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Navigation;